import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Login from './general/login'
import ProtectedView  from './protected_router'
import Forget from './general/forget'
import Register from './general/register'
import ChangePassword from './general/new_password'
import VerifyAccount from './general/verify_email'
import LoginB from './general/loginb'

function App() {
  return (<>
<ReactNotification />
       <Router  >
        <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/account_verify" component={VerifyAccount} />
        <Route exact path="/recover_password" component={ChangePassword} />
        <Route exact path="/forgot_password" component={Forget} />
        <ProtectedView />


   {/*      
        
    <Route exact path="/error403" component={error403} />
    <Route exact path="/error500" component={error500} />
    <Route exact  component={Error404} /> */}
        </Switch>
          </Router>
     </>
        );
      }

export default App;
