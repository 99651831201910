import React from 'react'
import Sidebar from './sidebar'

import Topbar from './topbar'
import Footer from './footer'

const  Template = (props:{children?:React.ReactNode, title?:string})=> {
    return (
      <>
<header>
    <Sidebar title={props.title} />
<Topbar />
</header>


<main style={{marginTop: '58px'}}>


{props.children}


<Footer/>
</main>


</>
    )
}

export default Template
