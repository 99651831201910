import React from 'react'

import { store } from 'react-notifications-component';


export const  Alerts =(title:string, types:'default' | 'success'| 'info'| 'warning' | 'danger', content?:string)=>{
  // 'default', 'success', 'info', 'warning', danger
  //top-left, top-right, top-center, center, bottom-left, bottom-right, bottom-center

  store.addNotification({
    title: title,
    message: content,
    type: types,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
      pauseOnHover: true
    }
  })
  
}



export const InfoModal = (props:{mID?:string, title?:string, handleConfirm?:()=>void}) =>{
  return      <div className="modal fade " id={`infoDialog-${props.mID}`} tabIndex={-1} role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title"><i className='fa fa-info fa-2x'></i> Wait!</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">
                <p>{props.title}</p>
              </div>
              <div className="modal-footer">
                  <button type="button"  id={`btnInfoDialog-${props.mID}`} className="btn btn-default waves-effect " data-dismiss="modal">No, Cancel</button>
                  <button type="button" onClick={props.handleConfirm} className="btn btn-outline-warning waves-effect">Yes I Am</button>
              </div>
          </div>
      </div>
  </div>
}

/* export const WarningModal = props =>{
  return      <div className="modal fade " id={`warningdialog-${props.mID}`} tabIndex={-1} role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              
              <div className="modal-body">
                <div className="text-center"><i className="fa fa-warning fa-5x text-warning"></i>
                  <h5>Hold On!</h5>
                <p>{props.message}</p>
              </div></div>
              <div className="modal-footer">
                  <button type="button"  id={`btnWarningDialog-${props.mID}`} className="btn btn-inverse waves-effect " data-dismiss="modal">No, Thanks</button>
                  <button type="button" onClick={props.handleAction} className="btn btn-outline-warning waves-effect">Yes I Am</button>
              </div>
          </div>
      </div>
  </div>
} */




export const Error = React.memo((props:{title:string}) =>{
  
  return   <div className='alert alert-danger background-danger' id='alert-message'  >
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <i className="icofont icofont-close-line-circled text-white"></i>
              </button>
  <h5><i className='fa fa-ban fa-2x'></i> Error!</h5>
  {props.title}
</div>
})

export const Warning = React.memo((props:{title:string})=>{
  return <div className="alert alert-warning background-warning" id='alert-message'><button type="button" className="close" data-dismiss="alert" aria-label="Close">
    <i className="icofont icofont-close-line-circled text-white"></i>
</button>
<strong><h5><i className='fa fa-warning fa-2x'></i> Warning!</h5></strong>  {props.title}
</div>
      
})

export const Info = React.memo((props:{title:string}) =>{
  return      <div className='alert alert-info background-info' id='alert-message'  ><button type="button" className="close" data-dismiss="alert" aria-label="Close">
  <i className="icofont icofont-close-line-circled text-white"></i>
</button>
  <h5><i className='fa fa-info fa-2x'></i> Info!</h5>
  {props.title}
</div>
})




/* 
export const SessionTimeout = (props) =>{
  return      <div className="modal fade " id='timeoutModal' tabIndex={-1} role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              
        <div className="modal-header">                                       
          <h4 className="modal-title">Your Session is About to Expire!</h4>        
          <button type="button" className="close" id='btnLogoutsession' data-dismiss="modal" aria-hidden="true">×</button>             
        </div> 

              <div className="modal-body">
               <p>Your session is about to expire. Do you want to stay connected?</p> 
              </div>

              <div className="modal-footer">
                  <button type="button" onClick={props.Logout} className="btn btn-default " >Logout</button>
                  <button type="button" onClick={props.Connected} className="btn btn-primary">Stay Connected</button>
              </div>
          </div>
      </div>
  </div>
}
 */

 
export default React.memo(Error)