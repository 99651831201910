import React, {useEffect, useState} from 'react'

import {ServerUrl,  config,  ImagesUrl} from '../components/include'
import axios from 'axios'

const Country = (props:{handleSelect:any, country:string})=>{

    const [province, setProvince] = useState([] as any)

    const handleFetchState = async()=>{
        let url = 'https://locus.fkkas.com/api/states'
        const {data} = await axios.get(url, config)
setProvince(data.data)


     }

     useEffect(()=>{
        handleFetchState()
     }, [])

    return <select className="form-control" id="country" name="country" value={props.country} onChange={props.handleSelect}>
        <option value=""> [Select State]</option> 
{province&&province.map((list:any, id:string)=>
    <option value={list.name} key={id}> {list.name}</option> )}
    
    </select>
}
export default React.memo(Country)