import React, {useState, useEffect, FormEvent, ChangeEvent} from 'react'
import {Alerts} from '../components/notify'
import {ServerUrl,  config,  ImagesUrl} from '../components/include'
import Footer from './footer'
import axios from 'axios'

const  Forget = ()=> {

    const [notice, setNotice] = useState({
        isLoading : false
    });
	const [user, setUser] = useState({
		password:'',
        confirmPassword:''
    })
    const [errors, setErrors] = useState({
        errorMessage:'',
        successMessage:'',
        confirmPassword:''
    });
    const handleChange = (event:ChangeEvent<HTMLInputElement>) =>{	
        let {name, value} = event.target;
       setUser({...user, [name] : value });
       setErrors({...errors, confirmPassword:''})
    }

  
 var myemail = new URLSearchParams(window.location.search).get("myemail") || ''
 var mytoken = new URLSearchParams(window.location.search).get("mytoken") || ''

        const submit =(event: FormEvent<HTMLFormElement>)=>{ 
            event.preventDefault();
            var errormessage = [];
    
          if(user.password !== user.confirmPassword){
              setErrors({...errors, confirmPassword:'Password does not match.'})
              errormessage.push('Password does not match.');
          }
    
            if (errormessage.length<=0) {

                setNotice({...notice,  	isLoading: true })

        const fd = new FormData();
        fd.append('password', user.password)
        fd.append('myemail', myemail)
        fd.append('mytoken', mytoken)

        let url = ServerUrl+'/update_controller.php?tablename=tbl_update_user_password';
		axios.post(url, fd, config)
		.then(response =>{
	if(response.data.type === 'success'){
    setErrors({...errors, successMessage: response.data.message})

			}else{
          setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
			}    
		})
		.catch((error)=>{
			  setErrors({...errors, errorMessage: error.message})
		}).finally(()=>{
            setNotice({...notice,  	isLoading: false }) 
          setUser({password:'', confirmPassword:''})
        })     
}
}


function verifyLink(){  


    if(myemail===null  || mytoken===null || mytoken==='' || myemail===''){
        window.open('/', '_self')
    
    }else{
        setNotice({...notice, isLoading: true}) 
    
          const fd = new FormData();
          fd.append('email', myemail)
          fd.append('token', mytoken)
    
          let url = ServerUrl+'/save_controller.php?tablename=tbl_verify_password_request';
          axios.post(url, fd, config)
          .then(response =>{
          if(response.data.type === 'success'){
                
            //setErrors({...errors, successMessage: 'Please create your new password'});
    
                }else {
                  setErrors({...errors, errorMessage: response.data.message})
                  setInterval(() => {
                  window.open('/', '_self')
                   }, 5000)
                }    
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
                 
            }).finally(()=>{
                setNotice({...notice, 
                    isLoading: false
                }) 
            })    
        } 
    }
    
    
    useEffect(()=>{  
       verifyLink()
    },[]);
    

    return (<>
        <header>
       
        <div id="intro" className="bg-image shadow-2-strong">
          <div className="mask d-flex align-items-center h-100" style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
            <div className="container">
               



            {errors.successMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-success" role="alert" data-mdb-color="success">
          <strong>Warning!</strong> {errors.successMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}
      
            {errors.errorMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-danger" role="alert" data-mdb-color="danger">
          <strong>Warning!</strong> {errors.errorMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}


              <div className="row justify-content-center ">
                <div className="col-xl-5 col-md-8">
                        
                    <form className="form" method="POST"  onSubmit={submit}>
                    <div className="card card-login card-hidden mb-3" style={{ background: 'linear-gradient(#1225f1, #0a1370)'}} >
                        <div className="card-header card-header-warning text-center">
                            <h4 className="card-title"><strong style={{color:'yellow'}}>Create New Password</strong></h4>
                        </div>
                        <div className="card-body">
                                                        
                                                        
                        <div className="bmd-form-group mt-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="material-icons">lock_outline</i>
                                      </span>
                                    </div>
                                    
                                    <input type="password" title="Must contain at least one number and one uppercase and lowercase letter, and at least 6 or more characters" pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{6,}$" name="password"  value={user.password} placeholder="Password..." required  onChange={handleChange} className='form-control pl-2 ' /> 
                                 

                                </div>
                                <span className=" form-bar error" style={{color: '#ff2e2e'}}>{ errors.confirmPassword}</span>
                               </div>


                            <div className="bmd-form-group mt-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="material-icons">lock_outline</i>
                                      </span>
                                    </div>
                                   
                                    <input type="password" title="Must contain at least one number and one uppercase and lowercase letter, and at least 6 or more characters" pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{6,}$" required  name="confirmPassword"  value={user.confirmPassword} placeholder="Confirm Password"  onChange={handleChange} className='form-control pl-2 ' /> 
        
                                </div>
                                <span className=" form-bar error" style={{color: '#ff2e2e'}}>{ errors.confirmPassword}</span>
                            </div>

                        </div>
                        <div className="card-footer justify-content-center">
                        {notice.isLoading?
          
          <button disabled className="btn btn-warning btn-link btn-lg" type="button">
              
              
              <img src={ImagesUrl+"/loading.gif"} /> 
              Please wait...</button>:    <button type="submit" className="btn btn-warning btn-link">Update Password</button>}
                        </div>
                    </div>
                </form>
                        
                    
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Footer />

      
      
      </>
        
    )
}

export default Forget
