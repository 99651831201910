import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react'
import { Alerts } from '../components/notify';
import axios from 'axios'
import {ServerUrl,  config, ImagesUrl,  Token, Code, Currency} from '../components/include'
import Template from '../components/template'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {FormatNumber, getTime} from '../components/globalFunction'


const  Investment = ()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [invest, setInvest] = useState([] as any[])



    const fetchContent =()=>{
        setNotice({...notice, isDataFetching: true}) 

        var sql ="Select i.code, i.ID, p.planName, p.duration, i.isPayout, i.investment, i.currentReturn, i.toReturn, i.returnPerDay, i.startDate, i.endDate, i.status, i.userCode from tbl_user_investment i, tbl_investment_plan p  where i.userCode ='"+Code+"'   and i.planId = p.code  order by i.ID DESC";

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 
            setInvest(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
    }

   
    const getCurrentReturn=(row:any)=>{
        
        if(row.isPayout === 'No'){
        var fromDate =  new Date(row.startDate);
        var toDate =  new Date();
        var diff_time = toDate.getTime()-fromDate.getTime()

        var differ = diff_time/(1000*3600*24);
        var total =0;
        if(Number(row.duration)>=Number(differ)){
             total = Number(differ) * Number(row.returnPerDay);
        }else{
            total = Number(row.duration) * Number(row.returnPerDay);
        }
       
        return total.toFixed(2)
    }else{

        return row.currentReturn
        }
    }

    const tableHeader = [
        {dataField: 'planName', text: 'Plan Name', editable: false},
        {dataField: 'investment', text: 'Investment', editable: false, formatter: (cell:Number)=>FormatNumber(cell)},

      
        {dataField: 'currentReturn', text: 'Current Return', editable: false, formatter:(i:string, row:any)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> { getCurrentReturn(row)+ ' '+Currency.title}</span>},

        {dataField: 'toReturn', text: 'Total to be returned', editable: false, formatter:(cell:number, row:any)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {FormatNumber(Number(cell)) + " "+Currency.title}</span>},

        {dataField: 'returnPerDay', text: 'Return per day', editable: false, formatter:(cell:number)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {cell + " "+Currency.title}</span>},

        {dataField: 'startDate', text: 'Start on', editable: false, formatter:(cell:string)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {cell.slice(0,19)}</span> },

        {dataField: 'endDate', text: 'Ends on', editable: false, formatter:(cell:string)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {cell.slice(0,19)}</span>},

        {dataField: 'status', text: 'Status', editable: false, formatter:(cell:string)=><span 
    
        className={` badge 
        ${cell==='Active'?'bg-success':
        cell==='Expired'?'bg-danger':
        cell==='Pending'?'bg-warning':
        cell==='Completed'?'bg-secondary':
                        'btn-info'}`}> {cell}</span>},

        {dataField: 'code', text: 'Earnings', editable: false, formatter: (cell:string)=><a href={"/earnings/"+cell}><button className="btn btn-primary btn-sm px-3 ripple-surface"><i className="fas fa-chart-line fa-fw "></i>  <div className="ripple-container"></div></button></a>},
              
     ];
  
     const BasicTable=(props:{data:any, columns:any})=>{
             
              const customTotal = (from:Number, to:Number, size:Number) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };
      
         return  <ToolkitProvider search 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               
                                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={'No Investment Found'}
                                     pagination={paginationFactory(options) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    


    useEffect(()=>{
        fetchContent() 
         },[]);


    return (<Template title="My Investments">


        <div className="container pt-4">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card py-4">
                    <div className="card-header">
                            <h5 className="header-title text-center">My Investments</h5>
                        </div>
                        <div className="card-body">

                            <div className="table-responsive">
                         <BasicTable data={invest} columns={tableHeader}  />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
</Template>
      
    )
}

export default Investment
