import React from 'react'
import Template from '../components/template'

const  Authenticator = ()=> {
    return (<Template>
       <div className="container pt-4">
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <h2 className="text-center">Authenticator</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">Two Factor Authentication</div>
                        <div className="card-body">
                            <p>Two factor authentication (2FA) strengthens access security by requiring two methods (also referred to as factors) to verify your identity. Two factor authentication protects against phishing, social engineering and password brute force attacks and secures your logins from attackers exploiting weak or stolen credentials.</p>
                            <br />
                            <p>To Enable Two Factor Authentication on your Account, you need to do following steps</p>
                            <ul style={{listStyleType:'none'}}>
                                <li>1. Click on Generate Secret Button , To Generate a Unique secret QR code for your profile</li>
                                <li>2. Verify the OTP from Google Authenticator Mobile App</li>
                            </ul>

                            
                            <button type="submit" className="btn btn-primary">Click Here to Generate Secret Key</button>
<div style={{display:'none'}}>
                                    <div className="sufee-alert alert with-close alert-success alert-dismissible fade show">
                                        Secret Key is generated, Please verify Code to Enable 2FA
                                    </div>
                                    <p><strong>1. Scan this barcode with your Google Authenticator App:</strong></p>
                                    <img src="#" />
                                    <p>Secret Key: <strong>DTRB5X67KF53GBPT</strong></p>
                                    <p><strong>2.Enter the pin the code to Enable 2FA</strong></p>
                                    <form method="post" action="#">                                    <div className="form-group bmd-form-group">
                                            <label htmlFor="authenticator" className="bmd-label-static">Authenticator Code</label>
                                            <input type="text" className="form-control" name="code" required />
                                        </div>

                                        <button type="submit" className="btn btn-primary">Enable 2FA</button>
                                    </form>

                                    </div>
                                                    </div>
                    </div>
                </div>
            </div>

        </div>


        <div className="container " style={{display:'none'}}>
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <h2 className="text-center">Authenticator</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">Two Factor Authentication</div>
                        <div className="card-body">
                            <p>Two factor authentication (2FA) strengthens access security by requiring two methods (also referred to as factors) to verify your identity. Two factor authentication protects against phishing, social engineering and password brute force attacks and secures your logins from attackers exploiting weak or stolen credentials.</p>
                            <br />
                            <p>To Enable Two Factor Authentication on your Account, you need to do following steps</p>
                            <ul >
                                <li>1. Click on Generate Secret Button , To Generate a Unique secret QR code for your profile</li>
                                <li>2. Verify the OTP from Google Authenticator Mobile App</li>
                            </ul>

                            
                                
                                    <div className="sufee-alert alert with-close alert-success alert-dismissible fade show">
                                        2FA is Currently Enabled for your account.
                                    </div>

                                    <div className="sufee-alert alert with-close alert-info alert-dismissible fade show">
                                        <span className="badge badge-pill badge-success">Info:</span>
                                        If you are looking to disable Two Factor Authentication. Please confirm your 2FA and Click Disable 2FA Button.
                                    </div>

                                    <form method="post" action="https://kingstradeltd.com/authenticator/disable">
                                                                              <div className="form-group bmd-form-group">
                                            <label htmlFor="authenticator" className="bmd-label-static">Authenticator Code</label>
                                            <input type="text" className="form-control" name="code" />
                                        </div>

                                        <button type="submit" className="btn btn-primary" name="disable">Disable 2FA</button>
                                    </form>

                                
                                                    </div>
                    </div>
                </div>
            </div>

        </div>

</Template>
      
    )
}

export default Authenticator
