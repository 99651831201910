import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react'
import { Alerts } from '../components/notify';
import axios from 'axios'
import {ServerUrl,  config, ImagesUrl,  Token, Code, Currency} from '../components/include'
import Template from '../components/template'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { useSelector } from 'react-redux'
import {FormatNumber} from '../components/globalFunction'

const  Deposit = ()=> {

    //API key
   // 23455247-990b-465b-893d-ab18535e472e

    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [transaction, setTransaction] = useState([] as any[])

    const [topup, setTopup] = useState({
        amount:'',
        method:'Bank',
    })


    const user  = useSelector((state:any) => state.rootReducer.user);
    const handleChange = (event:ChangeEvent<HTMLInputElement>) =>{		
        let {name, value} = event.target;	
        setTopup({...topup, [name] : value });
    }

    const handleChangeSelect = (event:ChangeEvent<HTMLSelectElement>) =>{		
        let {name, value} = event.target;	
        setTopup({...topup, [name] : value });
    }
    const fetchContent =()=>{
        setNotice({...notice, isDataFetching: true}) 

        var sql ="Select code, userCode, amount, status, chargeId, dateCreate, balanceBefore, balanceAfter, transactionMethod from tbl_deposit where userCode ='"+Code+"' order by ID DESC";

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 
            setTransaction(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
    }

    const [errors, setErrors] = useState({
        errorMessage:'',
        successMessage:''
    });


    const tableHeader = [

        {dataField: 'dateCreate', text: 'Date Create', editable: false},
        {dataField: 'transactionMethod', text: 'Method', editable: false},

        {dataField: 'amount', text: 'Amount', editable: false, formatter: (cell:Number)=>FormatNumber(cell)+' '+Currency.title},

        {dataField: 'status', text: 'Status', editable: false},


        {dataField: 'balanceBefore', text: 'Balance Before', editable: false, formatter:(cell:number)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {FormatNumber(cell) + " "+Currency.title}</span>},

        {dataField: 'balanceAfter', text: 'Balance After', editable: false, formatter:(cell:number)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {FormatNumber(cell) + " "+Currency.title}</span>},

        {dataField: 'chargeId', text: 'Upload Evidence', editable: false, formatter:(cell:number)=><a href={'/topup/pay/'+cell}> Click Here</a>}

              
     ];

  
     const BasicTable=(props:{data:any, columns:any})=>{
             
              const customTotal = (from:Number, to:Number, size:Number) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };
      
         return  <ToolkitProvider search 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               
                                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={'No Transaction Found'}
                                     pagination={paginationFactory(options) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    

      const submit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
        var errormessage = [];

        if(Number(topup.amount) < 50 || Number(topup.amount) > 100000  ){
            errormessage.push('error');

            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
        setErrors({...errors, errorMessage: 'Deposit amount must be between 50.00 and 100000.00.'})

        }
          

      /*    if (topup.method === 'Online Payment'){

            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
            setErrors({...errors, errorMessage: 'This option is currently not availble'})
            errormessage.push('error');
            }  */




     if (errormessage.length <=0) {
       setNotice({...notice, isLoading: true})     
        const fd = new FormData();
        
        var balanceAfter = Number(user.balance) + Number(topup.amount);

        fd.append('userCode', Code);
        fd.append('amount', topup.amount);
        fd.append('status', 'Waiting for funds...');
        fd.append('balanceBefore', user.balance);
        fd.append('username', user.firstname + ' '+user.lastname);
        fd.append('email', user.email);
        
        fd.append('balanceAfter', balanceAfter.toString());
        fd.append('method', topup.method);
        fd.append('code', 'd'+Math.random().toString(36).slice(2,9));
        fd.append('jwt', Token);

        let url = ServerUrl+'/create_charges_coinbase.php?tablename=tbl_deposit_fund';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.type === 'success'){
            window.open('/topup/pay/'+response.data.chargeId, '_self')
                  } else{
         setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                  }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{

            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
               setNotice({...notice,  isLoading: false })
              
              })    
        } 
    }


    useEffect(()=>{
        fetchContent() 
         },[]);


    return (<Template title="Top up">
       <div className="container pt-4">


       {errors.errorMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-danger" role="alert" data-mdb-color="danger">
          <strong>Warning!</strong> {errors.errorMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}




            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <h2 className="text-center">Top up</h2>
                </div>
            </div>


            <div className="row">
                <div className="col-lg-6 offset-lg-3">
                    <div className="card">
                        <div className="card-body">
                            <form id="action" method="post" action="#!" onSubmit={submit}>
                                <div className="form-group bmd-form-group is-filled">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{Currency.unicode}</span>
                                        </div>
                                        <input type="number" value={topup.amount}  onChange={handleChange} className="form-control" id="amount" placeholder='Amount..' name="amount" pattern="\d*" required  />
                                        <div className="input-group-append">
                                            <span className="input-group-text">{Currency.unicode}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="method">Deposit Method</label>
                                    <select id="method" name="method" className="form-control" required value={topup.method} onChange={handleChangeSelect}>
                                            <option value="Bank">   Bank Deposit (50.00 {Currency.unicode} - Unlimited {Currency.unicode})  </option>
           <option value="Online Payment">     Online Payment (50.00 {Currency.unicode} - Unlimited {Currency.unicode})   </option>
                       </select>
                                </div>
                               

                              {notice.isLoading?
          
          <button disabled className="btn btn-primary " type="button">
              
              
              <img src={ImagesUrl+"/loading.gif"} /> 
              Please wait...</button>: <div>
                         <button type="submit" className="btn btn-success mt-3">Top Up</button>
                                <br/><span className="text-warning">Min. and max. deposit amount may vary for each deposit method!</span></div>}

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <br />

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                    <div className="card-header">
                            <h3 className="header-title text-center">Deposits</h3>
                        </div>

                        <div className="card-body">
                            <div className="table-responsive">
                                <small><p className="text-warning">Bank Deposit are visible only after successful deposits and confirmation</p></small>
                                
                                
                                <BasicTable data={transaction} columns={tableHeader}  />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</Template>
      
    )
}

export default Deposit
