import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react'
import { Alerts } from '../components/notify';
import axios from 'axios'
import {ServerUrl,  config, ImagesUrl,  Token, Code, Currency} from '../components/include'
import Template from '../components/template'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {FormatNumber} from '../components/globalFunction'

const  Earnings = ()=> {

    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [earn, setEarn] = useState([] as any[])



    const fetchContent =()=>{
        setNotice({...notice, isDataFetching: true}) 

        var sql =" Select p.planName,  e.ID, e.amountEarn, e.balanceBefore, e.balanceAfter,  e.creditAt, u.investment from tbl_earnings e, tbl_investment_plan p, tbl_user_investment u  where  p.code = e.planCode and u.code = e.contractCode and e.userCode ='"+Code+"' order by e.ID DESC";

       

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 
            setEarn(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
    }


    const tableHeader = [
        {dataField: 'planName', text: 'Contract', editable: false},
        {dataField: 'investment', text: 'Investment', editable: false, formatter:(cell:number)=><span> {FormatNumber(cell)+ ' '+Currency.title}</span>},
        {dataField: 'amountEarn', text: 'Amount Earn', editable: false, formatter:(cell:Number)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {FormatNumber(cell)}</span>},

        {dataField: 'balanceBefore', text: 'Balance Before', editable: false, formatter:(cell:number)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {cell + " "+Currency.title}</span>},

        {dataField: 'balanceAfter', text: 'Balance After', editable: false, formatter:(cell:number)=><span style={{display:'block', width:80, whiteSpace:'normal', wordWrap:'break-word' }}> {cell + " "+Currency.title}</span>},
        {dataField: 'creditAt', text: 'Receive At', editable: false},
              
     ];

  
     const BasicTable=(props:{data:any, columns:any})=>{
             
              const customTotal = (from:Number, to:Number, size:Number) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };
      
         return  <ToolkitProvider search 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               
                                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={'No Earnings Found'}
                                     pagination={paginationFactory(options) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    


    useEffect(()=>{
        fetchContent() 
         },[]);


    return (<Template title="Earnings">
       <div className="container pt-4">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="header-title text-center">My Earnings</h5>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <BasicTable data={earn} columns={tableHeader}  />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
</Template>
      
    )
}

export default Earnings
