import React, {useState, useEffect, FormEvent, ChangeEvent} from 'react'
import {Alerts} from '../components/notify'
import {ServerUrl,  config,  ImagesUrl} from '../components/include'
import Navbar from './navbar'
import Footer from './footer'
import axios from 'axios'

const  Verify = ()=> {

    const [notice, setNotice] = useState({
        isLoading : false
    });
	
    const [errors, setErrors] = useState({
        errorMessage:'',
        successMessage:'',
        confirmPassword:''
    });
   

 var myemail = new URLSearchParams(window.location.search).get("myemail") || ''
 var mytoken = new URLSearchParams(window.location.search).get("mytoken") || ''


function verifyLink(){  


    if(myemail===null  || mytoken===null || mytoken==='' || myemail===''){
        window.open('/', '_self')
    
    }else{
        setNotice({...notice, isLoading: true}) 
    
          const fd = new FormData();
          fd.append('email', myemail)
          fd.append('token', mytoken)
    
          let url = ServerUrl+'/save_controller.php?tablename=tbl_verify_user';
          axios.post(url, fd, config)
          .then(response =>{
          if(response.data.type === 'success'){
                
            setErrors({...errors, successMessage: response.data.message});
           
                }else {
                  setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                
                }    
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
                 
            }).finally(()=>{
                setNotice({...notice, 
                    isLoading: false
                }) 

                setInterval(() => {
                    window.location.href="/"
                 // window.open('/', '_self')
                   }, 5000)
            })    
        } 
    }
    
    useEffect(()=>{  
      verifyLink()
    },[]);
    

    return (
        <div className="off-canvas-sidebar" >
            
        
        <div className="wrapper wrapper-full-page">
          <div className="page-header login-page header-filter" filter-color="black"
               style={{backgroundSize: 'cover !important', background: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${ImagesUrl+"/banner7.jpg"}) repeat `, alignItems: 'center'}}
               data-color="danger">
        
                <div className="container" >


        {errors.successMessage?
   <div className="alert alert-dismissible alert-success">
   <button type="button" className="close" data-dismiss="alert">×</button>
   <strong>Success!</strong> {errors.successMessage}
</div>:''}



{errors.errorMessage?
   
   <div className="alert alert-dismissible alert-danger">
                       <button type="button" className="close" data-dismiss="alert">×</button>
                       <strong>Error!</strong> {errors.errorMessage}
                   </div>:''}



                
            </div>
           <Footer />
          </div>
        </div>
    
        </div>
        
    )
}

export default Verify
