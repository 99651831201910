import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react'
import { Alerts } from '../components/notify';
import axios from 'axios'
import Template from '../components/template'
import {ServerUrl,  config, ImagesUrl,  Token, Code, Currency} from '../components/include'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FormatNumber } from '../components/globalFunction';

const  PaymentPlan = ()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [deposit, setDeposit] = useState([] as any[])
    const [bank, setBank] = useState({} as any)

    const user  = useSelector((state:any) => state.rootReducer.user);
//to get transaction details
//https://api.commerce.coinbase.com/charges/coinbaseid

    let { chargeCode } = useParams<{ chargeCode: string }>();
    

    const [evidence, setEvidence] = useState({
        charge_code:chargeCode,
        email:user.email,
        user_code: Code,
        photo:'',
        file_url:'',
        imagesFile:''
    });

    const [errors, setErrors] = useState({
        imagesFile:'',
        photo:'',
        errorMessage:'',
        successMessage:''
     })

     const copyToClipboard =(link:string)=>{
         
        const el = document.createElement('textarea');
        el.value = link;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        Alerts('Info!', 'info', 'wallet copied to clipboard')
    }

     const fetchBank =()=>{
        setNotice({...notice, isDataFetching: true}) 


        var sql ="Select bank_name, account_number, account_name, iban, switch_code, address from tbl_bank_account where code ='eds' limit 1";

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 
            if(result.data.length!==0){
                setBank(result.data[0])
            }
            
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
    }


    const fetchContent = async ()=>{
        setNotice({...notice, isDataFetching: true}) 
        var sql ="Select code, transactionMethod, amount, status, mailSent, chargeId, coinbaseid from tbl_deposit where chargeId ='"+chargeCode+"'";
         
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        await axios.post(url, fd, config).then(result=>{ 
            setDeposit(result.data)  

            if(Array.isArray(result.data) && result.data[0].mailSent && result.data[0].mailSent ==='No'){
                const fd = new FormData();
                 fd.append("chargeId", chargeCode);
                 fd.append("amount", result.data[0].amount);
                 fd.append("username", user.firstname);
                 fd.append("email", user.email);
                fd.append('jwt', Token);
                
                let url = ServerUrl+'/save_controller.php?tablename=tbl_send_deposit_mail';
                axios.post(url, fd, config)
                .then(response =>{
                    //console.log('i sent a mail')
                }
                )
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
        
    }

    
  

   const  handleFile = function(fieldName:any){
    return function(newValue:any){                 
   readURL(newValue);
   }
  } 

 

const readURL = (input:any)=>{
 
let displayMessage = '';

const PreviewImage = input.target.name;
const inputElement = document.getElementById('photo') as HTMLInputElement
const fileName = inputElement.value

var idxDot = fileName.lastIndexOf(".") + 1;
var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
    //TO DO    
    
     var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
if (FileSize > 1) {
    displayMessage = ' File uploaded is larger than maximum size Allow (500 kb).';
    inputElement.value = '';
 
    setEvidence({...evidence, file_url:'', photo:'', imagesFile:''})

} else {
    if (input.target.files && input.target.files[0]) { //Check if input has files.
        var reader = new FileReader();//Initialize FileReader.
        reader.onload = function (e:any) {
            
        //Check if input has files.
        let Id = Math.random().toString(36).substring(2, 9)
         let fileUrl = Id+'.png'

         setEvidence({...evidence,
            file_url:fileUrl, 
            photo:e.target.result, 
            imagesFile:input.target.files[0]})
          setErrors({...errors, photo:''}); 

        }
        reader.readAsDataURL(input.target.files[0]);
        
    }
}
}else{
displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 500 kb'
inputElement.value = '';
setEvidence({...evidence, file_url:'', photo:'', imagesFile:''})
}   

if(displayMessage.length > 0){
    Alerts('Error!', 'danger', displayMessage)
}
}

const handleConfirmPayment =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();


    let error = {...errors}; 
    let formIsValid = true;


if(!evidence.photo){
    error.photo ='Please upload your evidence of payment';
    formIsValid = false;
} 

if(!formIsValid){
    setErrors(error) 
    }



if(formIsValid) {

    setNotice({...notice, isLoading: true})  
    const fd = new FormData();

    Object.entries(evidence).forEach(([key, value]) => {
        fd.append(key, String(value));
    });

    if(evidence.imagesFile){
        fd.append('imageFiles', evidence.imagesFile); 
    } 
        fd.append('jwt', Token);

        let url = ServerUrl+'/update_controller.php?tablename=tbl_submit_evidence';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.type === 'success'){
            setErrors({...errors, successMessage: response.data.message})

                  } else{
         setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                  }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{

            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
                let inputElement = document.getElementById('photo') as HTMLInputElement
                inputElement.value = ''
                setEvidence({
                    ...evidence,
                    photo:'',
                    file_url:'',
                    imagesFile:''
                });
               setNotice({...notice,  isLoading: false })
              
              })    
        } 


}

   
    let wallet = 'TRicyVFNKis7rXh98WWLf4iLvfajmFeHTN'

    useEffect(()=>{
        fetchContent() 
        fetchBank()
         },[]);

    return (<Template title="Top up">
        <div className="container pt-4">
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <h2 className="text-center">Pay into the following Account</h2>
                </div>
            </div>

            {errors.successMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-success" role="alert" data-mdb-color="danger">
          <strong>Success!</strong> {errors.successMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}

            {errors.errorMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-danger" role="alert" data-mdb-color="danger">
          <strong>Warning!</strong> {errors.errorMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}

                <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    <div className="card">
                        <div className="card-body">


                {deposit.length!==0? deposit.map((item, id)=>   
                  item.transactionMethod ==='Online Payment'?<div>

<div  className="jumbotron bg-white text-center">

<h3 className="display-4 text-dark">Wallet </h3>
<button className="btn btn-primary  mt-2" onClick={()=>copyToClipboard(wallet)} >Copy Wallet</button>

<h5 className=" text-dark" onClick={()=>copyToClipboard(wallet)}>{wallet}</h5>


                        <h1 className="display-4 text-dark">Amount - { Currency.unicode+FormatNumber(item.amount)}</h1>

                        <img  className='img-100  pointer '  src={ImagesUrl+'/wallet.jpeg'} alt="preview" />

</div>
                  </div>:	  <div key={id} className="jumbotron bg-white text-center">

<h3 className="display-4 text-dark">Bank - {bank.bank_name}</h3>
<h3 className="display-4 text-dark">Account Number - {bank.account_number}</h3>
<h3 className="display-5 text-dark">Account Name - {bank.account_name}</h3>

<h3 className="display-5 text-dark">IBAN Number - {bank.iban}</h3>
<h3 className="display-5 text-dark">Switch Code - {bank.switch_code}</h3>
<h3 className="display-5 text-dark">Address - {bank.address}</h3>
                        <h1 className="display-4 text-dark">Amount - { Currency.unicode+FormatNumber(item.amount)}</h1>


                      
                    </div>):''}

                  



                    <div  className="jumbotron bg-white text-center">


                        <p className="lead text-dark">Please upload your evidence of payment and click on confirm payment below</p>

 

 <span className='text-danger'>{errors.photo}</span>
                        <input type="file" id='photo' className='form-control' onChange={handleFile('photo')} name="photo"  accept="image/*" />

                        {evidence.imagesFile?
 <img id="viewPassport2" className='img-100  pointer form-control'  src={evidence.photo} alt="preview" /> : '' }  


{notice.isLoading?
          
          <button disabled className="btn btn-primary " type="button">
              
              
              <img src={ImagesUrl+"/loading.gif"} /> 
              Please wait...</button>: <button type='button' onClick={handleConfirmPayment} className="my-3 btn btn-primary">Confirm Payment</button>}

                        <hr className="my-4" />
                        <p className="text-small text-muted">Direct Bank payments are processed within 24 hours.</p>
                        <p className="lead">
                            <a className="btn btn-primary btn-lg" href="/topup" role="button">go back</a>
                        </p>
                    </div>




                </div></div></div>
            </div>

        </div>
</Template>
      
    )
}

export default PaymentPlan
